import { post } from "./service";
import { UserRole } from "./constants";

export function logIn(credential) {
  let url = "/Account/login";

  return new Promise((resolve, reject) => {
    post(url, credential)
      .then(res => {
        setStorageToken(JSON.stringify(res));
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function logOut() {
  setStorageToken(undefined);
}

export function isloggedIn() {
  return !(
    window.localStorage["token"] === undefined ||
    window.localStorage["token"] === null ||
    window.localStorage["token"] === "null" ||
    window.localStorage["token"] === "undefined" ||
    window.localStorage["token"] === ""
  );
}

export function getToken() {
  if (
    window.localStorage["token"] === undefined ||
    window.localStorage["token"] === null ||
    window.localStorage["token"] === "null" ||
    window.localStorage["token"] === "undefined" ||
    window.localStorage["token"] === ""
  ) {
    return "";
  }

  let obj = JSON.parse(window.localStorage["token"]);
  return obj;
}

export function setStorageToken(value) {
  window.localStorage["token"] = value;
}

export function currentRole() {
  if (isloggedIn()) {
    return getToken().role;
  }
  return null;
}

export function isPriorityUser() {
  let role = currentRole();
  let employeeRole = [UserRole.MANAGER, UserRole.ADMIN];
  return employeeRole.includes(role);
}

export function isEngineer() {
  let role = currentRole();
  return role === UserRole.ENGINEER;
}
