import { fakePrefix, fakeAbbr } from "./../_helpers/fake-url";

function endpoint(options) {
  let opts = { ...options }; //clone object
  let url = opts.url;

  let body = opts.body;
  let fakeIndex = url.indexOf(fakePrefix);
  if (fakeIndex < 0) {
    throw new Error("url not contains fake prefix");
  }

  let startRealUrl = url.indexOf("/");
  let realUrl = url.substring(startRealUrl); //

  let fakeContent = url.substring(0, startRealUrl).match(/\((.*)\)/);
  let fakeUrl = fakeContent[1].split("|")[1]; //content in rounded brakets

  //dropdown
  if (url.includes("dropdown")) {
    return DropdownService.endpoint(url, opts);
  }
}

export default { endpoint };
