import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import * as http from "./js/service";
import UtilsMixins from "./_mixins/utils-mixins";

Vue.mixin({ ...UtilsMixins }); //global mixins
Vue.prototype.$http = http;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
