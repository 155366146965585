export default {
  data() {
    return {};
  },
  methods: {
    catchError(err) {
      let message = "";
      if (typeof err === "string") {
        message = err;
      } else if (err && err.message) {
        message = err.message;
      } else if (err && err.errors) {
        let arr = _.toArray(err.errors);
        message = arr.join(", ");
      }

      this.showAlertError(message);
    },
    showAlertError(message) {
      let defaultMessage = "Error";
      // const options = { ...globalOptions };
      // options.type = "error";
      // options.icon = "warning";

      // alert(message || defaultMessage);
    }
  },
  computed: {
    isServerProduction() {
      return true;
    }
  }
};
