import { InputType, DATE_PICKER_TYPE, DateFormat } from './constants';
import moment from 'moment';
import _ from 'lodash';
import { toBeautyFormat } from '../js/date-helper';



export function urlToPayload(url) {
    if (!url) {
        url = "";
    }
    let arr = url.split('?');

    let queryString = '';
    if (arr.length > 1) {
        queryString = arr[1];
    }

    if (queryString) {
        let str = '{"' + decodeURI(queryString)
            .replace(/"/g, '\\"').replace(/&/g, '","')
            .replace(/=/g, '":"') + '"}';

        let obj = JSON.parse(str);

        return obj;
    }
    return {};
}


export function filterString(key, criteria, arr) {
    arr = arr.filter(x => {
        let str = x[key] || ''

        if (str.includes(criteria[key])) {
            return x
        }
    })
    return arr;
}

//reference https://stackoverflow.com/a/31415775
//to modified for support customer.id, original version customer[id]
export function toQueryString(obj, urlEncode = false) {
    function flattenObj(x, path) {
        var result = [];

        path = path || [];
        Object.keys(x).forEach(function (key) {
            if (!x.hasOwnProperty(key)) return;

            var newPath = path.slice();
            newPath.push(key);
            var vals = [];
            if (x[key]) {
                if (typeof x[key] == 'object') {
                    vals = flattenObj(x[key], newPath);
                } else {
                    vals.push({ path: newPath, val: x[key] });
                }
            }
            vals.forEach(function (obj) {
                return result.push(obj);
            });
        });

        return result;
    } // flattenObj

    // start with  flattening `obj`
    var parts = flattenObj(obj); // [ { path: [ ...parts ], val: ... }, ... ]

    // convert to array notation:
    parts = parts.map(function (varInfo) {
        if (varInfo.path.length == 1) varInfo.path = varInfo.path[0]; else {
            var first = varInfo.path[0];
            var rest = varInfo.path.slice(1);
            varInfo.path = first + '.' + rest.join('.');
        }
        return varInfo;
    }); // parts.map

    // join the parts to a query-string url-component
    var queryString = parts.map(function (varInfo) {
        return varInfo.path + '=' + varInfo.val;
    }).join('&');
    if (urlEncode) return encodeURIComponent(queryString); else return queryString;
}


export function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function chunkArray(arr, n) {
    var chunkLength = Math.max(arr.length / n, 1);
    var chunks = [];
    for (var i = 0; i < n; i++) {
        if (chunkLength * (i + 1) <= arr.length)
            chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
    }
    return chunks;
}

export function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}


export async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export function getUID() {
    // uuidv4
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
}

export function toCurrencyFormat({ value, decimal = 2 }) {
    if (!value && value !== 0) {
        value = ""
    }

    value = value.toString().replace(/,/g, '');
    let num = parseFloat(value)
    let result = (!isNaN(num)) ? num.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : '';
    return result
}

export function flagDisabledAllFields({ rows, disabled }) {
    rows.forEach(items => {
        //each row
        items.forEach(item => {
            item.disabled = disabled;
        });
    });

    return _.cloneDeep(rows);
}

export function getDropdownItem(obj) {
    return _.pick(obj, ["id", "text"]);
}

export function createDynamicModel(context, model, rows) {
    let inputs = getElementCreateModel(rows)

    inputs.forEach(input => {
        let defaultVal = null;

        if (typeof input.default === 'number') {
            defaultVal = input.default.toString()
        } else {
            defaultVal = input.default || null;
        }

        context.$set(model, input.name, _.cloneDeep(defaultVal));
    })

    function getElementCreateModel(rows) {
        let elements = _.flatten(rows);
        elements = elements.filter(row => row.name);

        return elements;

    }
}

export function addressStructure() {
    return _.cloneDeep({
        province: null,
        district: null,
        subDistrict: null,
        houseNumber: null,
        moo: null,
        road: null,
        trok: null,
        soi: null
    })
}

export function addressToString(addr) {

    let houseNumber, moo, road, trok, soi, subDistrict, district, province, postCode;
    houseNumber = moo = road = trok = soi = subDistrict = district = province = postCode = "";

    if (addr.houseNumber) {
        houseNumber = addr.houseNumber;
    }
    if (addr.moo) {
        moo = ` หมู่ที่ ${addr.moo}`;
    }
    if (addr.road) {
        road = ` ถนน${addr.road}`;
    }
    if (addr.trok) {
        trok = ` ตรอก${addr.trok}`;
    }
    if (addr.soi) {
        soi = ` ซอย${addr.soi}`;
    }
    if (addr.subDistrict) {
        subDistrict = ` ตำบล${addr.subDistrict.thaiName}`;
        postCode = ` ${addr.subDistrict.postalCode}`;
    }
    if (addr.district) {
        district = ` อำเภอ${addr.district.thaiName}`;
    }
    if (addr.province) {
        province = ` จังหวัด${addr.province.thaiName}`;
    }

    return `${houseNumber}${moo}${trok}${soi}${road}
          \n${subDistrict}${district} 
          \n${province}${postCode}`;
}

export function getNamePrefixString(model) {
    let prefix = "";
    if (model.namePrefix) {
        if (model.namePrefix.id !== NamePrefix.otherId) {
            prefix = model.namePrefix.text;
        } else {
            prefix = model.namePrefixRemark;
        }
    }
    return prefix;
}

export function openNewTabWithDelay({ url, dalay = 1000 }) {
    setTimeout(function () {
        let a = document.createElement("a");
        a.target = "_blank";
        a.href = url;
        a.click();
    }, dalay);
}

export function wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
        end = new Date().getTime();
    }
}

export function getCurrentTime() {
    let date = moment().format(DateFormat.dateStandardFormat)
    let buddhisFormat = toBeautyFormat(date)
    let result = `${buddhisFormat} ${moment().format('HH:mm')}`;
    return result;
}


//check only string property
export function isEmpty(item) {
    let isEmpty = Object.values(item)
        .filter(x => typeof x === "string")
        .every(x => x === null || x === "");
    return isEmpty;
}

export function isFormData(data) {
    return data instanceof FormData
}

export function createValidateRule(input) {
    let condition = {};

    const ruleConfig = input.rule || {};
    //required
    if (ruleConfig.required) {
        condition.required = true;
    }

    if (ruleConfig.email) {
        condition.email = true;
    }


    //date, month picker
    if (input.type === InputType.datePicker) {
        condition.dateformat = DATE_PICKER_TYPE.DATE;
    }

    if (input.type === InputType.monthPicker) {
        condition.dateformat = DATE_PICKER_TYPE.MONTH;
    }
    //end date, month picker

    //time picker
    if (input.type === InputType.timePicker) {
        condition.timeformat = true;
    }
    //end time picker

    if (ruleConfig.number) {
        condition.regex = /^[0-9]\d*(\.\d+)?$/;
    }

    //length
    if (ruleConfig.min) {
        condition.min = ruleConfig.min;
    }
    //length
    if (ruleConfig.max) {
        condition.max = ruleConfig.max;
    }
    //  numberic
    if (ruleConfig.min_value) {
        condition.min_value = ruleConfig.min_value;
    }
    //  numberic
    if (ruleConfig.max_value) {
        condition.max_value = ruleConfig.max_value;
    }

    if (ruleConfig.is_not) {
        condition.is_not = ruleConfig.is_not;
    }

    if (ruleConfig.confirmed) {
        condition.confirmed = ruleConfig.confirmed;
    }

    if (ruleConfig.regex) {
        condition.regex = ruleConfig.regex;
    }
    if (ruleConfig.alpha) {
        condition.alpha = true;
    }

    return condition;
}


export const InputRelate = {
    getProvinceRelate() {
        return {
            name: "province",
            text: "จังหวัด",
            type: InputType.dropdownRelate,
            prop: {
                id: "id",
                text: "thaiName"
            },
            data: {
                url: "/dropdown/provinces"
            }
        }
    },
    getDistrictRelate() {
        return {
            name: "district",
            text: "อำเภอ",
            type: InputType.dropdownRelate,
            parent: "province",
            parentField: "provinceId",
            prop: {
                id: "id",
                text: "thaiName"
            },
            data: {
                url: "/dropdown/districts?provinceId="
            }
        }
    },
    getSubDistrictRelate() {
        return {
            name: "subDistrict",
            text: "ตำบล",
            type: InputType.dropdownRelate,
            parent: "district",
            parentField: "districtId",
            prop: {
                id: "id",
                text: "thaiName"
            },
            data: {
                url: "/dropdown/subdistricts?districtId="
            }
        }
    }
}