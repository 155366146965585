export const Server = {
  Local: "local",
  Development: "development",
  Staging: "staging",
  Production: "production"
};

export class DateFormat {
  static diffThaiYear = 543;
  static beautyDateFormat = "DD/MM/YYYY";
  static dateStandardFormat = "YYYY-MM-DD";

  static beautyPeriodFormat = "MM/YYYY";
  static PeriodStandardFormat = "YYYY-MM";
}

export const DATE_PICKER_TYPE = {
  DATE: "date",
  MONTH: "month"
};

export const AgreementType = {
  Normal: "Normal",
  Demo: "Demo"
};

export const UserRole = {};
export const InputType = {};
