import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

const Test = () => import("./../views/test");
const ConvertPdf = () => import("./../views/convert-pdf");
const Toro = () => import("./../views/toro");
const Dicom = () => import("./../views/dicom");
const CheckGoogle = () => import("./../views/check-google2");
const TransactionSale = () => import("./../views/transaction-sale1");
const TransactionSale2 = () => import("./../views/transaction-sale2");
const TransactionPayment1 = () => import("./../views/transaction-payment1");
const TransactionPayment2 = () => import("./../views/transaction-payment2");
const Pipaksa = () => import("./../views/pipaksa");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "transaction-payment2"
  },
  // {
  //   path: "/toro",
  //   name: "toro",
  //   component: Toro
  // },
  // {
  //   path: "/transaction-sale",
  //   name: "transaction-sale",
  //   component: TransactionSale
  // },
  {
    path: "/dicom",
    name: "dicom",
    component: Dicom
  },
  {
    path: "/check-google",
    name: "check-google",
    component: CheckGoogle
  },
  {
    path: "/transaction-sale2",
    name: "transaction-sale2",
    component: TransactionSale2
  },

  {
    path: "/transaction-payment2",
    name: "transaction-payment2",
    component: TransactionPayment2
  },
  {
    path: "/pipaksa",
    name: "pipaksa",
    component: Pipaksa
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
